<section
   *ngIf="customers?.length > 0; else withoutService"
   class="kavalli-table"
   [@slideAnimation]="tableType !== 'stats' ? 'notActive' : 'active'"
>
   <p-table
      #kavalliTable
      [value]="customers"
      [paginator]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50]"
      [globalFilterFields]="filterName"
      [rows]="5"
      responsiveLayout="stack"
      dataKey="id"
      [breakpoint]="'768px'"
   >
      <ng-template pTemplate="caption">
         <div class="flex">
            <span class="p-input-icon-left ml-auto search-header">
               <span class="icon-search icon"></span>
               <input
                  pInputText
                  type="text"
                  (input)="kavalliTable.filterGlobal($any($event.target)?.value, 'contains')"
                  placeholder="Pesquise aqui..."
               />
            </span>
         </div>
      </ng-template>
      <ng-template pTemplate="header">
         <tr>
            <th
               [ngStyle]="{ width: column === 'Foto' || 'Id' ? '5%' : '25%' }"
               *ngFor="let column of columns"
               style="width: 25%"
            >
               {{ column }}
            </th>
         </tr>
      </ng-template>
      <!-- ---- Users --- -->
      <ng-template *ngIf="tableType === 'users'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td *ngIf="customer.photo; else defaultPhoto">
               <img [src]="customer.photo" style="border-radius: 50%" width="40px;" />
            </td>
            <ng-template #defaultPhoto>
               <td style="width: 5%">
                  <img src="assets/imgs/avatar.svg" width="40px" style="border-radius: 50%" />
               </td>
            </ng-template>
            <td>{{ customer.name }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ customer.cellphone }}</td>
            <td>{{ customer.bornDate ? customer.bornDate : 'N/A' }}</td>
            <td>
               <div class="row-inline">
                  <span
                     *ngIf="customer.phone && customer.email"
                     [ngClass]="{
                        'color-primary-button--disabled': !customer.phone && !customer.email
                     }"
                     class="icon-key color-primary-button"
                     (click)="eventItemClicked(customer, 'generate')"
                     [tooltip]="
                        customer.phone && customer.email
                           ? 'Gerar senha OTP'
                           : 'Cadastrar Email e Senha'
                     "
                  ></span>
                  <!-- <span
                     role="button"
                     (click)="eventItemClicked(customer, 'edit')"
                     tooltip="Editar usuário"
                     class="icon-pencil color-primary-button"
                  ></span> -->
                  <!-- 
                     TODO: Verificar lógica de delete, estava dando treta
                     <img
                     role="button"
                     src="assets/imgs/trash.svg"
                     (click)="eventItemClicked(customer.id, 'del')"
                     tooltip="Deletar usuário"
                     class="edit-button"
                  /> -->
               </div>
            </td>
         </tr>
      </ng-template>
      <!-- ---- Schedule --- -->
      <ng-template *ngIf="tableType === 'schedule'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.address }}</td>
            <td>{{ customer.customer.name ? customer.customer.name : 'N/A' }}</td>
            <td>
               <mat-checkbox [(ngModel)]="customer.recurring" [disabled]="true"></mat-checkbox>
            </td>
            <!-- <td>{{ customer.customer.recurring ? 'Sim' : 'Não' }}</td> -->
            <td [ngSwitch]="customer.status">
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'active'"
                  severity="success"
                  value="Active"
               ></p-tag>
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'inactive'"
                  [style]="{ background: '#727272' }"
                  value="Inactive"
               ></p-tag>
            </td>
            <td>
               {{ customer.startDate }} | {{ this.convertTimestampToTime(customer.startTime) }}
            </td>
            <td>
               <div class="row-inline">
                  <span
                     role="button"
                     (click)="eventItemClicked(customer, 'edit')"
                     tooltip="Editar agenda"
                     class="icon-pencil color-primary-button"
                  ></span>
                  <!-- <span
                     role="button"
                     (click)="eventItemClicked(customer, 'cancel')"
                     tooltip="Cancelar"
                     class="icon-cancel-circle color-primary-button"
                  ></span> -->
                  <span
                     role="button"
                     (click)="displayInformationSchedule(customer)"
                     tooltip="Ver dados"
                     class="icon-info-circled color-primary-button"
                  ></span>
               </div>
            </td>
         </tr>
      </ng-template>
      <!-- ---- SERVICES --- -->
      <ng-template *ngIf="tableType === 'services'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.phone }}</td>
            <td>{{ customer.website }}</td>
            <td>{{ customer.status }}</td>
            <td>     
               <div class="row-inline">
               <span
               role="button"
               (click)="eventItemClicked(customer, 'edit')"
               tooltip="Editar"
               class="icon-pencil color-primary-button"
               ></span>
               <span
                  role="button"
                  (click)="eventItemClicked(customer, 'cancel')"
                  tooltip="Cancelar"
                  class="icon-cancel-circle color-primary-button"
               ></span> 
            </div>
            </td>
         </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
         <tr>
            <td colspan="7" class="no-found">Sem resultados.</td>
         </tr>
      </ng-template>
   </p-table>
</section>

<ng-template #withoutService>
   <div class="without-service">
      <h1>Sem serviço</h1>
   </div>
</ng-template>
