<form class="telphone-input" [formGroup]="form">
   <label for="phone">{{ label }}</label>
   <div class="telphone-input__form">
      <p-dropdown
         [options]="countries"
         [(ngModel)]="selectedCountry"
         [filter]="true"
         optionLabel="name"
         filterFields="name,idd"
         [formControlName]="dropdownControlName"
      >
         <ng-template pTemplate="selectedItem">
            <div class="telphone-input__form-country-selected" *ngIf="selectedCountry">
               <img [src]="selectedCountry.img" style="width: 22px" />
               <div>{{ selectedCountry.idd }}</div>
            </div>
         </ng-template>
         <ng-template let-country pTemplate="item">
            <div class="telphone-input__form-country-dropdown">
               <div class="inner-dropdown">
                  <img [src]="country.img" style="width: 22px" />{{ country.name }}
               </div>
               <span>{{ country.idd }}</span>
            </div>
         </ng-template>
      </p-dropdown>
      <input type="text" [formControlName]="inputControlName" mask="(000) 000-000000" />
   </div>
</form>
