import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from '@core/pages/not-found/not-found.component'
import { AuthenticatedGuard } from '@guards/authenticated.guard'
import { DisableGuardGuard } from '@guards/disable.guard'

const routes: Routes = [
   { path: '', redirectTo: 'stats', pathMatch: 'full' },
   {
      path: 'stats',
      loadChildren: () => import('./core/pages/stats/stats.module').then(m => m.StatsModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'users',
      loadChildren: () => import('./core/pages/users/users.module').then(m => m.UsersModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'services',
      loadChildren: () =>
         import('./core/pages/services/services.module').then(m => m.ServicesModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'update-user',
      loadChildren: () =>
         import('./core/pages/users-update/users-update.module').then(m => m.UsersUpdateModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'login',
      loadChildren: () => import('./core/pages/auth/auth.module').then(m => m.AuthModule),
      canActivate: [DisableGuardGuard],
   },
   {
      path: 'users/new',
      loadChildren: () =>
         import('./core/pages/new-user/new-user.module').then(m => m.NewUserModule),
      canActivate: [AuthenticatedGuard],
   },
   { path: '404', component: NotFoundComponent, data: { disabledPermission: true } },
   { path: '**', redirectTo: '404', pathMatch: 'full' },
]

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
