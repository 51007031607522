import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CountriesService } from '@services/countries-service.service';
import { Subscription } from 'rxjs';

interface Country {
  name: string;
  img: string;
  idd: string;
  continent?: string;
  code?: string;
}

@Component({
  selector: 'telphone-input',
  templateUrl: './telphone-input.component.html',
  styleUrls: ['./telphone-input.component.scss']
})
export class TelphoneInputComponent implements OnInit, OnDestroy {
  constructor(private countriesService: CountriesService) {}
  public countries: Country[];
  public selectedCountry: any;
  public subscription: Subscription[] = []

  @Input() form: FormGroup;
  @Input() inputControlName: string;
  @Input() dropdownControlName: string;
  @Input() label: string;
  @Output() dataOut: EventEmitter<any> = new EventEmitter<any>();

  get inputControl() {
    return this.form.get(this.inputControlName) as FormControl;
  }

  get dropdownControl() {
    return this.form.get(this.dropdownControlName) as FormControl;
  }
  
  ngOnDestroy(): void {
    this.subscription.forEach(s => s.unsubscribe);
  }

  ngOnInit(): void  {
    this.getCountries();
  }
  
  getCountries() {
    this.subscription.push(
      this.countriesService
      .getCountries()
      .subscribe({
        next: (data) => {
          this.countries = Object.values(data).map((country: any) => ({
            name: country.name,
            img: country.img,
            idd: country.idd,
            code: country.code
          }));
          this.setValueDropdown();
          this.dataOut.emit(this.countries)
        }
      })
    )
  }

  setValueDropdown() {
    if (this.dropdownControlName === 'idd') {
      this.form.valueChanges.subscribe((e) => {
        this.selectedCountry = this.countries.find((el: any) => el.name === e.idd.name)
      })
    }
  }
  
}
