import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ModalService } from '@shared/services/dialog/modal.service'
import { DialogScheduleComponent } from '../dialog-schedule/dialog-schedule.component';

@Component({
  selector: 'kavalli-table',
  templateUrl: './kavalli-table.component.html',
  styleUrls: ['./kavalli-table.component.scss'],
  animations: [
    trigger('slideAnimation', [
      state('notActive', style({ transform: 'translateX(0)', opacity: 1 })),
      state('active', style({})),
      transition('* => notActive', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('500ms ease-out')
      ]),
      transition('notActive => active', animate('500ms ease-in')),
      transition(':enter', animate('500ms ease-out')),
      transition(':leave', animate('500ms ease-in')),
    ])
  ]
})
export class KavalliTableComponent {
  constructor(public router: Router, private dialog: ModalService,) {}
  @Input() customers: any;
  @Input() columns: string[];
  @Input() subColumns: string[];
  @Input() loadingTable: boolean;
  @Input() tableType: string;
  @Input() filterName!: any;
  
  @Output() outPutEventCustomer = new EventEmitter<any>();
  
  eventItemClicked(customerItems: any, event?: any) {
    this.outPutEventCustomer.emit({customerItems, event});
  }

  convertTimestampToTime(time: string) {
    let formattedTime = moment(time, "HH:mm:ss").format("HH:mm A");
    return formattedTime;
  }

  displayInformationSchedule(item: any) {
    this.dialog
       .open(DialogScheduleComponent, { data: item, panelClass: "schedule-dialog" })
       .afterClosed()
       .subscribe(result => {
          if (result) {
          }
       });
  }

}
