export interface NavMenuInterface {
   id: number
   title: string
   route: string
   icon: string
   collapseMenu: boolean
   subRouting?: [
      {
         name: string
         path: string
      },
   ]
}

export const navMenuItemsPT: NavMenuInterface[] = [
   { id: 1, title: 'Painel', route: 'stats', icon: 'icon-home', collapseMenu: false },
   { id: 2, title: 'Usuários', route: 'users', icon: 'icon-users', collapseMenu: false },
   { id: 3, title: 'Serviços', route: 'services', icon: 'icon-bag', collapseMenu: false },
]

export const navMenuItemsEN: NavMenuInterface[] = [
   { id: 1, title: 'Dashboard', route: 'stats', icon: 'icon-home', collapseMenu: false },
   { id: 2, title: 'Users', route: 'users', icon: 'icon-users', collapseMenu: false },
]
