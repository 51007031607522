import { Location } from '@angular/common'
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ELocalStorage } from '@enums/localstorage.enum'
import { EnumsStorage } from '@enums/sessionStorage.num'
import { TranslateService } from '@ngx-translate/core'
import { navMenuItemsPT, navMenuItemsEN } from '@shared/components/sidemenu/routes'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
   public titleText: string
   public subscriptions: Subscription[] = []
   public showTitle = true
   public toggleTransition: boolean = false
   public showSideBar = true
   public active = true
   public browserLang: string
   public navi18n: any
   constructor(
      private location: Location,
      private router: Router,
      private translateService: TranslateService,
   ) {}

   @HostListener('window:resize', ['$event'])
   onResize(event: any) {
      this.setSideBarByWidth(event.target.innerWidth)
      //  console.log('A largura da janela é: ', event.target.innerWidth);
      //  console.log('A altura da janela é: ', event.target.innerHeight);
   }

   /* istanbul ignore next */
   ngOnInit(): void {
      this.verifyLanguage()

      this.subscriptions.push(
         this.router.events
            .pipe(
               map(() => {
                  const locationSegment = this.location.path().split('/')?.[1]

                  this.titleText = this.getTitleText(locationSegment)

                  const hideForRoutes = [
                     'login',
                     'cadastre-se',
                     'carrinho',
                     'cadastro-sucesso',
                     '404',
                  ]

                  const shouldHide = hideForRoutes.includes(locationSegment)
                  if (!shouldHide) this.setSideBarByWidth(window.innerWidth)
                  else this.active = false
                  this.showSideBar = !shouldHide
                  this.showTitle = shouldHide
                  setTimeout(() => {
                     this.toggleTransition = !shouldHide
                  })
               }),
            )
            .subscribe(),
      )
   }

   private getTitleText(locationSegment: string): string {
      const texts: { [key: string]: string } = {
         stats: 'Dashboard',
         usuarios: 'Usuários',
         services: 'Serviços',
      }
      return texts[locationSegment] || ''
   }

   verifyLanguage() {
      const langSelected = JSON.parse(sessionStorage.getItem(EnumsStorage.USER_LANG) || '{}')
      const validationLangBrowser = Object.keys(langSelected).length
      const langBrowser = this.translateService.getBrowserLang()
      const currentLang = validationLangBrowser ? langSelected.value : langBrowser
      this.browserLang = currentLang
      this.verifyMenu()
      this.change(currentLang)
   }

   verifyMenu() {
      this.browserLang === 'pt' ? (this.navi18n = navMenuItemsPT) : (this.navi18n = navMenuItemsEN)
   }

   /* istanbul ignore next */
   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   public change(lkang: string): void {
      this.translateService.use(lkang)
   }

   private setSideBarByWidth(width: number) {
      this.active = width >= 1200
   }
}
