import { Component, Input, OnInit } from "@angular/core";
import { ELocalStorage } from "@enums/localstorage.enum";
import { ModalService } from "@shared/services/dialog/modal.service";
import { UserDataService } from "@shared/services/userdata.service";
import { DialogProfileImgComponent } from "../dialog-profile-img/dialog-profile-img.component";
import { UserService } from "@services/user.service";
import { NavMenuInterface } from "./routes";
import { Router } from "@angular/router";

@Component({
   selector: "sidemenu",
   templateUrl: "./sidemenu.component.html",
   styleUrls: ["./sidemenu.component.scss"],
})
export class SidemenuComponent implements OnInit {
   @Input() active: boolean;
   @Input() browserLang: string;
   @Input() navi18n: any;

   public routers: NavMenuInterface[] = [];
   public arrowToggle: boolean;
   profileImg: string | null
   constructor(
      public userDataService: UserDataService,
      private dialog: ModalService,
      private userService: UserService,
      private router: Router
   ) {}

   ngOnInit(): void {
      const role = this.getRole() || [];
      for (const item of this.navi18n) {
         const findRole = role.routes?.find((e: any) => e === item.route || e === "*");
         if (findRole === "*") {
            this.routers = this.navi18n;
         } else if (findRole && findRole !== "*") {
            this.routers.push(item);
         }
      }
   }

   openCropper(){
      this.dialog
      .open(DialogProfileImgComponent, { panelClass: "cropper" })
   }

   getRole() {
      const userDataStr: any = localStorage.getItem(ELocalStorage.USER_DATA);
      const userData = JSON.parse(userDataStr);
      return userData?.role;
   }

   animatedArrow() {
      this.arrowToggle = !this.arrowToggle
   }

   activeSubmenu(route: NavMenuInterface) {
      this.routers.forEach((r) => {
         if (r.id === 3 && route.id === 3) {
           r.collapseMenu = !r.collapseMenu;
         } else {
           r.collapseMenu = false;
         }
       });
       if (route.subRouting) {
         this.arrowToggle = !this.arrowToggle
       }
   }

   logOut() {
      this.userService.logout()
   }
}
