import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
   @Input() active: boolean;
   @Output() toggle = new EventEmitter<boolean>()
   toggleComments: boolean = false
   toggleNotifications: boolean = false
}
