import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environment'
import { DashboardInterface } from '@interfaces/dashboard.interface'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
   providedIn: 'root',
})
export class DashboardService {
   private readonly apiUrl = environment.apiUrl

   constructor(private httpClient: HttpClient) {}

   getDashboardData(): Observable<DashboardInterface> {
      return this.httpClient
         .get<DashboardInterface>(`${this.apiUrl}dashboard`)
         .pipe(map((response: any) => response))
   }
}
