import { Component, OnInit } from '@angular/core'
import { DashboardService } from '@services/dashboard.service'
import { Subscription } from 'rxjs'

@Component({
   selector: 'kavalli-tags',
   templateUrl: './tags.component.html',
   styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
   public subscriptions: Subscription[] = []

   constructor(private dashboardService: DashboardService) {}

   public dataTags: any = []

   ngOnInit(): void {
      this.displayLocalization()
   }

   displayLocalization() {
      this.subscriptions.push(
         this.dashboardService
            .getDashboardData()
            .pipe()
            .subscribe({
               next: (resp: any) => {
                  this.dataTags.push(
                     {
                        value: resp.totalUsers,
                        label: 'Usuários registrados',
                        color: '#ffc107',
                        icon: 'icon-user-add',
                     },
                     {
                        value: resp.totalServices,
                        label: 'Negócios ativos',
                        color: '#17A2B8',
                        icon: 'icon-bag',
                     },
                  )
               },
               error: error => {
                  const data: any = {
                     title: 'Error',
                     text: error.error.message,
                     type: 'error',
                     icon: 'cancel-circle',
                  }
               },
            }),
      )
   }
}
