<ng-container *ngIf="data.typeDialog === 1">
   <div class="dialog-associate" *ngIf="steps === 1">
      <h3>Deseja associar este usuário ao seu projeto?</h3>
      <p>{{ data.item.name }} será associado ao seu projeto.</p>
      <button
         type="submit"
         [class.btn-loader--start]="isLoading"
         class="btn-loader"
         (click)="displayAssociate()"
      >
         Associar
      </button>
   </div>

   <div class="dialog-associate" *ngIf="steps === 2">
      <h3 class="error-title">{{ errorData.error.message }}</h3>
      <button class="btn-loader" (click)="dialogRef.close()">Ok, Entendi</button>
   </div>
</ng-container>
<ng-container *ngIf="data.typeDialog === 2"></ng-container>
<ng-container *ngIf="data.typeDialog === 3"></ng-container>
