import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AuthInterceptor } from "@services/interceptors/auth.interceptor";
import { LoadingInterceptor } from "@services/interceptors/loading.interceptor";
import { SharedModule } from "@shared/shared.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from './core/pages/not-found/not-found.component';
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

registerLocaleData(localePt);
@NgModule({
   declarations: [AppComponent, NotFoundComponent],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      SharedModule,
      HttpClientModule
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoadingInterceptor,
         multi: true,
      },
      {
         provide: LOCALE_ID,
         useValue: "pt-BR",
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
