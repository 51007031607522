<base-dialog title="{{ title }}">
   <div class="schedule-container">
      <main class="schedule-container__wrapper">
         <div class="separator">
            <div class="line1"></div>
            <span class="text">Agenda</span>
            <div class="line2 pre-title"></div>
         </div>
         <div class="schedule-container__wrapper-box">
            <div class="heading">
               <p class="heading__description">
                  Início:
                  <span class="heading__description-value">
                     {{ this.convertDateApiToDisplay(data.startDate) }}</span
                  >
               </p>
               <p *ngIf="data.endDate" class="heading__description">
                  Fim:
                  <span class="heading__description-value">
                     {{ this.convertDateApiToDisplay(data.endDate) }}</span
                  >
               </p>
               <p *ngIf="data.endDate" class="heading__description">
                  Hora iniciada:
                  <span class="heading__description-value"> {{ data.startTime }}</span>
               </p>
            </div>
            <div class="body">
               <div class="body__columns">
                  <p>Nome da Agenda:</p>
                  <span>{{ data.name }}</span>
               </div>
               <div class="body__columns">
                  <p>Endereço:</p>
                  <span>{{ data.address }}</span>
               </div>
               <div class="body__columns">
                  <p>Status Agenda:</p>
                  <span [class.badge--inactive]="data.status === 'inactive'" class="badge">{{
                     data.status === 'active' ? 'Active' : 'Inactive'
                  }}</span>
               </div>
               <div class="body__columns">
                  <p>Recorrente:</p>
                  <span>{{ data.recurring ? 'Sim' : 'Não' }}</span>
               </div>
            </div>
         </div>
         <div class="separator">
            <div class="line1"></div>
            <span class="text">Customer</span>
            <div class="line2 pre-title"></div>
         </div>
         <div class="schedule-container__wrapper-box">
            <div class="body">
               <div class="body__columns">
                  <p>Nome:</p>
                  <span>{{ data.customer.name }}</span>
               </div>
               <div class="body__columns">
                  <p>Número para contato:</p>
                  <span
                     ><a [href]="'tel:+' + data.customer.contacts">{{
                        data.customer.contacts
                     }}</a></span
                  >
               </div>
               <div class="body__columns">
                  <p>Endereço:</p>
                  <span>{{ data.customer.address }}</span>
               </div>
            </div>
         </div>
         <div class="separator" *ngIf="data?.users.length > 0">
            <div class="line1"></div>
            <span class="text">Usuários</span>
            <div class="line2 pre-title"></div>
         </div>
         <div class="schedule-container__wrapper-box" *ngIf="data?.users.length > 0">
            <div class="wrappers">
               <div *ngFor="let item of data?.users" class="wrappers__content">
                  <p>
                     Nome: <span>{{ item.user.name }}</span>
                  </p>
                  <p>
                     Telefone: <a [href]="'tel:+' + item.phone">{{ item.user.phone }}</a>
                  </p>
               </div>
            </div>
         </div>
         <div class="separator mt-1">
            <div class="line1"></div>
            <span class="text">Dias da Semana</span>
            <div class="line2 pre-title"></div>
         </div>
         <div class="schedule-container__wrapper-box">
            <ul class="container" *ngIf="data.weekdays; else notWeeks">
               <li class="days" *ngFor="let day of weekDays" [ngClass]="getClass(day.value)">
                  {{ day.name }}
               </li>
            </ul>
            <ng-template #notWeeks>
               <ul class="container">
                  <li class="days day-inactive" *ngFor="let day of weekDays">
                     {{ day.name }}
                  </li>
               </ul>
            </ng-template>
         </div>
      </main>
   </div>
</base-dialog>
