<div class="tags">
   <div class="tags-content">
      <div *ngFor="let tag of dataTags" class="tags-content__inner" [style.background]="'#fff'">
         <div class="tags-content__inner-content">
            <div class="tags-content__inner-content-data">
               <p class="value">{{ tag?.value }}</p>
               <p class="title">{{ tag?.label }}</p>
            </div>
            <span [class]="tag.icon" [style.color]="tag.color"></span>
         </div>
      </div>
   </div>
</div>
