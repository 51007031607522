<nav class="side-bar" [class.side-bar--active]="active">
   <div class="side-bar__picture">
      <img
         *ngIf="active"
         class="side-bar__picture-logo"
         src="assets/imgs/logo-white.svg"
         alt="Logo Kavalli-admin.com.br"
         aria-label="Logo Kavalli-admin.com.br"
         [class.side-bar__logo--closed]="!active"
         routerLink="/stats"
      />
   </div>
   <ul class="side-bar__menu">
      <li
         *ngFor="let route of routers; let i = index"
         class="side-bar__menu__item"
         routerLinkActive="active"
         [class.side-bar__menu__item--closed]="!active"
         [class.side-bar__menu__item--auto-height]="route.collapseMenu"
      >
         <div
            (click)="activeSubmenu(route)"
            [routerLink]="route.route"
            class="side-bar__menu__item__content"
         >
            <div
               class="side-bar__menu__item__content-box"
               [class.side-bar__menu__item__content-box--closed]="!active"
            >
               <span [class]="route.icon"></span>
               <p *ngIf="active">{{ route.title }}</p>
            </div>
            <arrow
               *ngIf="route.subRouting"
               class="side-bar__menu__item__content-arrow"
               [type]="'bottom'"
               [active]="arrowToggle"
            ></arrow>
         </div>
         <ul
            class="side-bar__menu__item__sub-nav"
            [class.open]="arrowToggle"
            *ngIf="route.subRouting && arrowToggle"
         >
            <li
               class="side-bar__menu__item__sub-nav-link"
               [class.open]="arrowToggle"
               *ngFor="let item of route.subRouting"
            >
               <a [routerLink]="item.path" routerLinkActive="active">{{ item.name }}</a>
            </li>
         </ul>
      </li>
   </ul>
   <div class="side-bar__footer" [class.side-bar__footer--closed]="!active">
      <ng-container *ngIf="userDataService.userData$ | async as data">
         <span class="side-bar__footer-user">
            <button
               class="side-bar__footer-user-profile"
               (click)="openCropper()"
               [class.side-bar__footer-user-profile--img]="!!data?.photo"
            >
               <img
                  src="assets/imgs/avatar.svg"
                  class="img-avatar"
                  [class.img-avatar--closed]="!active"
                  [width]="!active ? '32px' : '40px'"
                  *ngIf="!data?.photo"
                  style="border-radius: 50%"
               />
            </button>
            <p>{{ data?.username | capitalizecase }}</p>
         </span>
         <!-- <p class="side-bar__footer-status">
            {{ 'SIDEMENU.FOOTER-TEXT' | translate }} {{ data?.lastLogin | date : 'dd/MM' }}
            {{ 'SIDEMENU.FOOTER-TEXT-COMPLEMENT' | translate }}
            {{ data?.lastLogin | date : 'HH' }}h{{ data?.lastLogin | date : 'mm' }}
         </p> -->
      </ng-container>

      <button class="side-bar__footer-logout" (click)="logOut()" id="logout">
         <span class="icon-exit"></span> {{ 'SIDEMENU.OUT' | translate }}
      </button>
   </div>
</nav>
